<template>
  <v-row class="pa-5">
    <v-col cols="12">
      <div class="mb-5 font-weight-bold" style="cursor: pointer; width: 100px">
        <v-btn
          @click="
            $router.push({
              name: 'RecapAbsent',
              query: {
                tab: 1,
                grade: $route.query.grade,
                class: $route.query.class
              }
            })
          "
          depressed
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("absent.absent_per_subjects") }}</v-btn
        >
      </div>
    </v-col>
    <v-col :class="isMobile && 'mb-2'" cols="12" sm="4">
      <v-radio-group
        v-model="roleType"
        row
        hide-details
        @change="isTeacher ? showDialog() : getLessonAbsent()"
      >
        <v-radio :label="$t('routes.student')" value="student" />
        <v-radio
          :label="isTeacher ? $t('absent.teacher_me') : $t('routes.teacher')"
          value="teacher"
        />
      </v-radio-group>
    </v-col>
    <v-col
      :class="!isMobile && 'd-flex align-center justify-end'"
      cols="12"
      sm="8"
    >
      <v-text-field
        v-model="search"
        :label="$t('app.search')"
        :class="isMobile ? 'my-2' : 'mr-2'"
        :style="!isMobile && 'max-width: 250px'"
        hide-details
        outlined
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        @keydown="searchHandler()"
        @click:clear="searchHandler()"
      />
      <v-select
        v-model="idDate"
        :items="dateItems"
        :loading="loadingDate"
        :label="$t('absencePerSubject.select_date')"
        :class="!isMobile && 'select-200'"
        item-text="dateFormat"
        item-value="date"
        outlined
        dense
        hide-details
        append-icon="mdi-calendar-range"
        @change="getLessonAbsent()"
      />
    </v-col>
    <v-col cols="12">
      <v-divider class="mt-3" />
    </v-col>
    <v-col cols="12">
      <v-row no-gutters justify="center">
        <v-col cols="6" md="4" lg="3" class="divider pl-2 mt-2">
          <div class="font-weight-light">{{ $t("app.subjects") }}</div>
          <div class="font-weight-bold">{{ lessonSchedule.subjectsName }}</div>
        </v-col>
        <v-col cols="6" md="4" lg="1" class="divider pl-2 mt-2">
          <div class="font-weight-light">{{ $t("app.grade") }}</div>
          <div class="font-weight-bold">{{ lessonSchedule.gradeName }}</div>
        </v-col>
        <v-col cols="6" md="4" lg="2" class="divider pl-2 mt-2">
          <div class="font-weight-light">{{ $t("app.class") }}</div>
          <div class="font-weight-bold">{{ lessonSchedule.className }}</div>
        </v-col>
        <v-col cols="6" md="4" lg="2" class="divider pl-2 mt-2">
          <div class="font-weight-light">{{ $t("app.school_year") }}</div>
          <div class="font-weight-bold">
            {{ lessonSchedule.schoolYear.start_year }} -
            {{ lessonSchedule.schoolYear.end_year }}
          </div>
        </v-col>
        <v-col cols="6" md="4" lg="1" class="divider pl-2 mt-2">
          <div class="font-weight-light">Semester</div>
          <div class="font-weight-bold">
            {{ lessonSchedule.schoolYear.semester }}
          </div>
        </v-col>
        <v-col cols="6" md="4" lg="2" class="divider pl-2 mt-2">
          <div class="font-weight-light">
            {{ $t("recap_absent.schedule") }}
          </div>
          <div class="font-weight-bold">
            {{ getDay(lessonSchedule.dow) }} : {{ lessonSchedule.time }}
          </div>
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.lg && 'justify-center'"
          cols="12"
          md="12"
          lg="1"
          class="d-flex align-center mt-2"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="rowsDataExcel.length === 0"
                class="gradient-primary"
                dark
                depressed
              >
                <download-excel
                  :fetch="() => rowsDataExcel"
                  :fields="fieldsExcel"
                  :name="$t('absent.absent_per_subjects')"
                  class="mr-1"
                >
                  <v-icon>mdi-cloud-download</v-icon>
                </download-excel>
              </v-btn>
            </template>
            <span>{{ $t("app.download") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider class="my-3" />
    </v-col>
    <v-col cols="12" class="mt-3">
      <v-data-table
        :headers="tableHeaders"
        :items="dataStudent"
        :items-per-page="dataStudent.length"
        :loading="loadingTable"
        item-key="id"
        disable-pagination
        hide-default-footer
        class="elevation-0"
      >
        <template #item.status_text="{ item }">
          <div v-if="!item.approved && item.status === '1'">
            <div>{{ item.status_text }}</div>
            <div>({{ $t("absent.absent_has_not_been_approved") }})</div>
          </div>
          <div v-else>{{ item.status_text }}</div>
        </template>
      </v-data-table>
    </v-col>

    <dialog-report
      :dialog="dialogReport"
      :subjectsName="lessonSchedule.subjectsName"
      :time="lessonSchedule.time"
      :className="lessonSchedule.className"
      :dataTable="myRecapData"
      @close="closeDialog"
    />
  </v-row>
</template>

<script>
import { dropDownDate, lessonAbsent } from "@/api/admin/absent/absent";
import i18n from "@/i18n";
import moment from "moment/moment";
import { get_school_year_list } from "@/api/admin/schoolClass";
import { myRecapPerSubjects } from "@/api/admin/absent/recapAbsent";

let typingTimer;
const doneTypingInterval = 750;

export default {
  created() {
    this.getDropDownDate();
  },
  components: {
    DialogReport: () => import("./components/DialogReport")
  },
  data() {
    return {
      isMobile: this.$vuetify.breakpoint.xsOnly,
      rowsDataExcel: [],
      fieldsExcel: {
        [i18n.t("app.name")]: "name",
        UID: "uid",
        [i18n.t("bk.table.status")]: {
          callback: item =>
            !item.approved && item.status === "1"
              ? item.status_text +
                " (" +
                i18n.t("absent.absent_has_not_been_approved") +
                ")"
              : item.status_text
        },
        [i18n.t("journal.table.information")]: "note"
      },
      tableHeaders: [
        {
          text: i18n.t("app.name"),
          align: "left",
          value: "name",
          width: 180
        },
        {
          text: "UID",
          value: "uid",
          width: 130
        },
        {
          text: i18n.t("bk.table.status"),
          value: "status_text",
          width: 130
        },
        {
          text: i18n.t("journal.table.information"),
          sortable: false,
          value: "note",
          width: 150
        }
      ],
      lessonSchedule: {
        className: "-",
        subjectsName: "-",
        time: "-",
        gradeName: "-",
        dow: null,
        schoolYear: {
          start_year: null,
          end_year: null,
          semester: "-"
        }
      },
      dialogReport: false,
      loadingTable: false,
      dataStudent: [],
      roleType: "student",
      idDate: null,
      dateItems: [],
      loadingDate: false,
      search: null,
      isTeacher: this.$store.getters.g_role_type === "TEACHER",
      myRecapData: []
    };
  },
  methods: {
    getDay(dow) {
      return dow ? this.$i18n.t("app.days")[dow - 2] : "-";
    },
    async showDialog() {
      const { id } = this.$route.params;
      try {
        const response = await myRecapPerSubjects({
          lesson_schedule: parseInt(id)
        });
        const { data } = response;
        if (data.code) {
          this.myRecapData = data.data;
          this.dialogReport = true;
        } else this.snackBar(false, i18n.t("app.there_is_an_error"));
      } catch (err) {
        console.error("showDialog()\n", err);
        this.snackBar(false, i18n.t("app.there_is_an_error"));
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    closeDialog() {
      this.dialogReport = false;
      if (this.isTeacher) this.roleType = "student";
      this.getLessonAbsent();
    },
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.getLessonAbsent();
      }, doneTypingInterval);
    },
    async getDropDownDate() {
      const { id } = this.$route.params;
      try {
        this.loadingDate = true;
        if (id) {
          const response = await dropDownDate({
            lesson_schedule: parseInt(id)
          });
          if (response.data.code) {
            this.dateItems = response.data.data
              .filter(item => moment(item.date).isBefore(moment()))
              .map(item => ({
                ...item,
                dateFormat: moment(item.date).format("DD MMM YYYY")
              }));
            if (this.dateItems.length) {
              this.idDate = this.dateItems[0].date;
              this.getLessonAbsent();
            }
          }
        }
      } catch (error) {
        console.error("getDropDownDate()\n", error);
      } finally {
        this.loadingDate = false;
      }
    },
    async getLessonAbsent() {
      const { id } = this.$route.params;
      try {
        this.loadingTable = true;
        if (id) {
          const response = await lessonAbsent({
            lesson_schedule: parseInt(id),
            date: this.idDate,
            role: this.roleType.toUpperCase(),
            search: this.search
          });
          if (response.data.code === 1 && response.data.data !== null) {
            const { lesson_schedules, data } = response.data.data;
            this.lessonSchedule.className = lesson_schedules.classname;
            this.lessonSchedule.subjectsName = lesson_schedules.lesson_text;
            this.lessonSchedule.gradeName = lesson_schedules.grade_name;
            this.lessonSchedule.dow = lesson_schedules.dow;
            this.lessonSchedule.time = `${lesson_schedules.start_time.substr(
              0,
              5
            )} - ${lesson_schedules.end_time.substr(0, 5)}`;
            this.dataStudent = data;
            this.rowsDataExcel = data;

            const schoolYearList = await get_school_year_list(false);
            if (schoolYearList.data.code === 1) {
              const { data } = schoolYearList.data;
              this.lessonSchedule.schoolYear = data.find(
                v => v.id === lesson_schedules.school_year
              );
            }
          } else {
            if (response.data.code === 0)
              this.snackBar(false, response.data.message);
            this.dataStudent = [];
          }
        }
      } catch (error) {
        console.error("getLessonAbsent()\n", error);
      } finally {
        this.loadingTable = false;
      }
    }
  }
};
</script>
